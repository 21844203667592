import React, { Fragment } from 'react';
import {
  Link
} from "react-router-dom";
import Loading from '../components/loading';

import { useDataContext } from '../context/data';

export default function News() {
  const { news } = useDataContext();
  return (
    <div className="container">
      <h1>Laatste nieuws</h1>
      {news.loading && <Loading />}
      {news.items && (
        <Fragment>
          { news.items.map(post => (
            <article key={post.id} className="box box--with-border news-item news-item--large">
              <Fragment>
                {post.featuredImage && (
                  <figure className="news__image">
                    <img alt={post.title} src={post.featuredImage?.node?.mediaItemUrl ? post.featuredImage.node.mediaItemUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNksPf9DwACYAGNJEiR2AAAAABJRU5ErkJggg=="} />
                  </figure>
                )}
                <section>
                  <div>
                    <h1>{post.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: post.excerpt || 'Lees het volledige bericht voor meer informatie'}} />
                    <Link to={`/nieuws/${post.slug}`} className="btn btn--green">
                      Meer lezen
                    </Link>
                  </div>
                </section>
              </Fragment>
            </article>
          ))}
          <div className="pagination">
            {news.pageInfo?.hasPreviousPage && <button className="btn btn--default" onClick={() => news.fetchNews(news.page-1, null, news.pageInfo.startCursor)}>Volgende</button>}
            {news.pageInfo?.hasNextPage && <button className="btn btn--default" onClick={() => news.fetchNews(news.page+1, news.pageInfo.endCursor)}>Vorige</button>}
          </div>
        </Fragment>
      )}
    </div>
  )
}
