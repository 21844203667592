import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import 'unfetch/polyfill'

export const request = async (
  url,
  query,
  variables = null,
) => {
  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  const result = await rawResponse.json();
  return result.data;
}

const DataContext = createContext();

export function DataWrapper({ children }) {
  // console.log(process.env.REACT_APP_API_URL);
  const url = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/graphql` : '/graphql';

  // const [menu, setMenu] = useState(window.menuItems || []);
  const [menu, setMenu] = useState([]);
  const [footer, setFooter] = useState([]);
  const [sponsors, setSponsors] = useState([]);

  const [matches, setMatches] = useState([]);

  const [knvbMatches, setKnvbMatches] = useState(null);

  const [news, setNews] = useState({});
  const [newsPage, setNewsPage] = useState(1);
  const [newsPageInfo, setNewsPageInfo] = useState({});
  const [newsLoading, setNewsLoading] = useState(true);
  const newsPageSize = 10;

  const initialFetch = useCallback(async () => {
    const data = await request(
      url,
      `query {
        posts (first: ${newsPageSize}) {
          nodes {
            id
            title
            slug
            excerpt
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
        ${menu.length === 0 ? `
          appMenuItems(where: {parent: 0, orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              title
              menuItemFields {
                blank
                url
              }
              children (where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                nodes {
                  ... on AppMenuItem {
                    id
                    title
                    menuItemFields {
                      blank
                      url
                    }
                  }
                }
              }
            }
          }
        ` : ''}
        ${footer.length === 0 ? `
          footerMenuItems(where: {parent: 0, orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              title
              footerItemFields {
                blank
                url
              }
              children (where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                nodes {
                  ... on FooterMenuItem {
                    id
                    title
                    footerItemFields {
                      blank
                      url
                    }
                  }
                }
              }
            }
          }
        ` : ''}
        sponsors(where: {orderby: {field: MENU_ORDER, order: ASC}}, first: 30) {
          nodes {
            title
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
        matches(first: 2) {
          nodes {
            title
            matchFields {
              date
              home
              result
              opponentLogo {
                mediaItemUrl
              }
            }
          }
        }
      }`
    );

    setNews({ 1: data.posts?.nodes || [] });
    setNewsPageInfo({ 1: data.posts?.pageInfo });
    setNewsLoading(false);
    setNewsPage(1);

    if (data.appMenuItems) {
      setMenu(data.appMenuItems.nodes.map((item) => ({
        label: item.title,
        url: item.menuItemFields.url || '/',
        blank: item.menuItemFields.blank || false,
        children: item.children?.nodes?.length > 0 ?
          item.children?.nodes?.map((subitem) => ({
            label: subitem.title,
            url: subitem.menuItemFields.url || '/',
            blank: subitem.menuItemFields.blank || false,
            children: [],
          }))
        :
          []
      })));
    }

    if (data.footerMenuItems) {
      setFooter(data.footerMenuItems.nodes.map((item) => ({
        label: item.title,
        url: item.footerItemFields.url || '/',
        blank: item.footerItemFields.blank || false,
        children: item.children?.nodes?.length > 0 ?
          item.children?.nodes?.map((subitem) => ({
            label: subitem.title,
            url: subitem.footerItemFields.url || '/',
            blank: subitem.footerItemFields.blank || false,
            children: [],
          }))
        :
          []
      })));
    }

    setSponsors(data.sponsors.nodes.map((item) => ({
        title: item.title,
        logo: item.featuredImage?.node?.mediaItemUrl || null,
      }
    )));

    setMatches(data.matches.nodes.map((item) => ({
      title: item.title,
      date: item.matchFields.date,
      home: item.matchFields.home || false,
      result: item.matchFields.result,
      opponentLogo: item.matchFields.opponentLogo?.mediaItemUrl || null,
    }
  )));
  }, [url, menu.length, footer.length]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  useEffect(() => {
    const knvbFetch = async () => {
      // eslint-disable-next-line no-undef
      if (knvbUrl) {
        try {
          const rawResponse = await fetch(
            // the knvb variables are set in the main page
            // eslint-disable-next-line no-undef
            `/knvb-data?hash=${knvbHash}&rand=${knvbRand}`,
            {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          });
          const result = await rawResponse.json();
          setKnvbMatches(result);
        } catch (e) {
          console.error(e);
        }
      }
    };
    knvbFetch();
  }, []);

  const fetchNews = async (newPage = 1, after = null, before = null) => {
    if(!news[newPage] || !newsPageInfo[newPage]) {
      setNewsLoading(true);
      let params = `first: ${newsPageSize}`;
      if (newPage > 1) {
        if (after) params = `${params}, after: "${after}"`;
        if (before) params = `${params}, before: "${before}"`;
      }

      const data = await request(
        url,
        `query {
          posts (${params}) {
            nodes {
              id
              title
              slug
              excerpt
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }`
      );

      setNews({
        ...news,
        [newPage]: data.posts?.nodes || []
      });
      setNewsPageInfo({
        ...newsPageInfo,
        [newPage]: data.posts?.pageInfo
      });
      setNewsLoading(false);
    }
    setNewsPage(newPage);
  };

  const state = {
    news: {
      page: newsPage,
      items: news[newsPage] || [],
      pageInfo: newsPageInfo[newsPage] || {},
      loading: newsLoading,
      fetchNews,
    },
    menu,
    footer,
    sponsors,
    matches,
    knvbMatches
  }

  return (
    <DataContext.Provider value={state}>
      {children}
    </DataContext.Provider>
  );
}

export function useDataContext() {
  return useContext(DataContext);
}
