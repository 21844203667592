import React, { Fragment, useState } from 'react';
import Loading from '../components/loading';
import {
  Link
} from "react-router-dom";

import { useDataContext } from '../context/data';

export default function News() {
  const { news, sponsors, matches, knvbMatches } = useDataContext();
  const [ knvbMatchesActiveTab, setKnvbMatchesActiveTab] = useState('home');

  if (news.loading) {
    return <Loading />
  }

  return (
    <Fragment>
      {sponsors && sponsors.length > 0 && (
        <div className="sponsors">
          <div className="container">
            <p>Wij bedanken onze sponsoren</p>
            <div className="sponsors__inner">
              {sponsors.map((sponsor) => (
                <figure key={sponsor.title} className="sponsors__item">
                  <img alt={sponsor.title} src={sponsor.logo || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNksPf9DwACYAGNJEiR2AAAAABJRU5ErkJggg=="} />
                </figure>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="container">
        { news.items && news.items[0] && (
          <article className="col__inner box box--with-border news-item news-item--large">
              <Fragment>
                {news.items[0].featuredImage && (
                  <figure className="news__image">
                    <img alt={news.items[0].title} src={news.items[0].featuredImage?.node?.mediaItemUrl ? news.items[0].featuredImage.node.mediaItemUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNksPf9DwACYAGNJEiR2AAAAABJRU5ErkJggg=="} />
                  </figure>
                )}
                <section>
                  <div>
                    <h1>{news.items[0].title}</h1>
                    <div dangerouslySetInnerHTML={{__html: news.items[0].excerpt || 'Lees het volledige bericht voor meer informatie'}} />
                    <Link to={`/nieuws/${news.items[0].slug}`} className="btn btn--green">
                      Meer lezen
                    </Link>
                  </div>
                </section>
              </Fragment>
          </article>
        )}

        <div className="row">
          <div className="col__s--12 col__m--6">
            {news && news.items.length > 0 && (
              <div className="col__inner box box--with-border">
                <h1>Laatste nieuws</h1>
                <ul>
                  {news.items.map(post => (
                    <li key={post.id}>
                      <Link to={`/nieuws/${post.slug}`}>
                        {post.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <Link to="/nieuws" className="btn btn--green">
                  Meer nieuws
                </Link>
              </div>
            )}
          </div>

          <div className="col__s--12 col__m--6">
            <div className="col__inner">
              <div className="box box--with-border">
                <h1>Komende wedstrijd</h1>

                <article className="match">
                  <section>
                    {matches && !matches[0] ? (
                      <Fragment>
                        {knvbMatches?.home && knvbMatches.home[0] ? (
                          <Fragment>
                            <div className="match__logos match__logos--home">
                              <img className="match__logos__svbc" alt="SVBC" src={process.env.PUBLIC_URL + "/logo.svg"} />
                              <img className="match__logos__opponent" alt="Tegenstander" src={process.env.PUBLIC_URL + "/opponent.svg"} />
                            </div>
                            {/* <h1>{matches[0].result}</h1> */}
                            <h2>
                              {knvbMatches.home[0].match}
                            </h2>
                            <p>
                              {knvbMatches.home[0].date} : {knvbMatches.home[0].time}
                            </p>
                          </Fragment>
                        ) : (
                          <h2>Geen wedstrijden gepland</h2>
                        )}
                      </Fragment>

                    ) : (
                      <Fragment>
                        <div className={`match__logos match__logos--${matches[0].home ? 'home' : 'away'}`}>
                          <img className="match__logos__svbc" alt="SVBC" src={process.env.PUBLIC_URL + "/logo.svg"} />
                          <img className="match__logos__opponent" alt={matches[0].title} src={matches[0].opponentLogo || process.env.PUBLIC_URL + "/opponent.svg"} />
                        </div>
                        <h1>{matches[0].result}</h1>
                        <h2>
                          {matches[0].title}
                        </h2>
                        <p>
                          {matches[0].date}
                        </p>
                      </Fragment>
                    )}
                  </section>
                </article>
                <Link to="/wedstrijden" className="btn btn--green">
                  Meer wedstrijden
                </Link>
              </div>
              <div className="box box--with-border">
                <h1>Webshop</h1>

                <a className="shop" title="SVBC Shop" href="https://clubs.deventrade.com/nl/vv-svbc/clubcollectie" target="_blank">
                  <img className="match__logos__svbc" alt="SVBC Shop" src={process.env.PUBLIC_URL + "/shop.png"} />
                </a>
                <a href="https://clubs.deventrade.com/nl/vv-svbc/clubcollectie" target="_blank" className="btn btn--green">
                  Naar de shop
                </a>
              </div>
            </div>
          </div>
        </div>

        {knvbMatches && (
          <div className="box box--with-border">
            <h1>Programma knvb</h1>
            <div className="toggles">
              {knvbMatches.home && knvbMatches.home.length > 0 && (
                <button
                  onClick={() => setKnvbMatchesActiveTab('home')}
                  className={`${knvbMatchesActiveTab !== 'home' ? 'inactive' : 'active'}`}
                >
                  Thuis<span>wedstrijden</span><span className="tag">{knvbMatches.home.length}</span>
                </button>
              )}
              {knvbMatches.home && knvbMatches.home.length > 0 && (
                <button
                  onClick={() => setKnvbMatchesActiveTab('away')}
                  className={`${knvbMatchesActiveTab !== 'away' ? 'inactive' : 'active'}`}
                >
                  Uit<span>wedstrijden</span><span className="tag">{knvbMatches.away.length}</span>
                </button>
              )}
            </div>
            <ul className="match-list">
              {knvbMatchesActiveTab === 'home' && (
                <Fragment>
                  {knvbMatches.home && knvbMatches.home.slice(0,5).map((line) => (
                    <li key={line.match}>
                      <div className="match-list__item">
                        <p>{line.date} : {line.time}</p>
                        <h3>{line.match}</h3>
                      </div>
                    </li>
                  ))}
                  {(!knvbMatches.home || knvbMatches.home.length === 0) && <li><div className="match-list__item"><p>Geen wedstrijden</p></div></li>}
                </Fragment>
              )}
              {knvbMatchesActiveTab === 'away' && (
                <Fragment>
                  {knvbMatches.away.slice(0,5).map((line) => (
                    <li key={line.match}>
                      <div className="match-list__item">
                        <p>{line.date} : {line.time}</p>
                        <h3>{line.match}</h3>
                      </div>
                    </li>
                  ))}
                  {(!knvbMatches.away || knvbMatches.away.length === 0) && <li><div className="match-list__item"><p>Geen wedstrijden</p></div></li>}
                </Fragment>
              )}
            </ul>
            <Link to="/wedstrijden" className="btn btn--green">
              Meer wedstrijden
            </Link>
          </div>
        )}

        {knvbMatches && (
          <div className="box box--with-border">
            <h1>Uitslagen knvb</h1>
            <ul className="match-list">
              {knvbMatches.results && knvbMatches.results.slice(0,5).map((line) => (
                <li key={line.match}>
                  <div className="match-list__item">
                    <p>{line.date} : {line.time}</p>
                    <h3>{line.match}</h3>
                    <p>{line.result}</p>
                  </div>
                </li>
              ))}
              {(!knvbMatches.results || knvbMatches.results.length === 0) && <li><div className="match-list__item"><p>Geen resultaten</p></div></li>}
            </ul>
            <Link to="/wedstrijden" className="btn btn--green">
              Meer uitslagen
            </Link>
          </div>
        )}

      </div>
    </Fragment>
  )
}
