import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import { DataWrapper } from './context/data';

import Header from './components/header';
import Footer from './components/footer';
// import Install from './components/install';

import News from './pages/news';
import Home from './pages/home';
import NewsItem from './pages/newsItem';
import Page from './pages/page';
import Matches from './pages/matches';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


export default function App() {
  return (
    <DataWrapper>
      <Router>
        <ScrollToTop />
        <Header />
        <main>
          <Routes>

            <Route exact path="/" element={<Home />} />
            <Route path="/nieuws" element={<News />} />
            <Route path="/nieuws/:slug" element={<NewsItem />} />
            <Route path="/wedstrijden" element={<Matches />} />
            <Route path="/:slug" element={<Page />} />
          </Routes>
        </main>
        <Footer />
        {/* <Install comingSoon /> */}
      </Router>
    </DataWrapper>
  );
};
