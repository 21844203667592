/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

export default function Loading() {
  return (
    <div
      className="loading"
    >
      <div />
    </div>
  )
}
