/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

export default function NotFound() {
  return (
    <article className="box box--with-border">
      <h1>Oeps we konden de pagina niet vinden</h1>
      <div>
        We hebben overal gezocht, zelfs in de kantine en kleedkamers, maar we konden deze pagina niet vinden..
      </div>
    </article>
  )
};
