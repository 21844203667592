import React, { useEffect, useState } from 'react';
// import { request } from 'graphql-request';
import {
  useParams
} from "react-router-dom";
import Loading from '../components/loading';
import NotFound from '../components/notFound';
import { request } from '../context/data';

export default function Page () {
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  let { slug } = useParams();

  useEffect(() => {
    const fetchPage = async () => {
      setLoading(true);
      const data = await request(
        process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/graphql` : '/graphql',
        `query {
          page(id: "${slug}", idType:URI) {
            content
            title
          }
        }`
        ,
        {}
      );

      setPage(data.page ? {
        ...data.page,
        content: data.page?.content?.replace(/\[\/?et_pb(.*?)\]/ig, '') || '',
      } : null);
      setLoading(false);
    }
    fetchPage();
  }, [slug]);

  return (
    <div className="container">
      {loading && <Loading />}
      {!loading && !page && <NotFound />}
      {!loading && page && (
        <article className="box box--with-border">
          <h1>{page.title}</h1>
          <div dangerouslySetInnerHTML={{__html: page.content}} />
        </article>
      )}
    </div>
  )
}
