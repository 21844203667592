import React, { Fragment, useState } from 'react';
// import Loading from '../components/loading';
import {
  Link
} from "react-router-dom";

import { useDataContext } from '../context/data';

export default function News() {
  const { matches, knvbMatches } = useDataContext();
  const [ knvbMatchesActiveTab, setKnvbMatchesActiveTab] = useState('home');
  return (
    <Fragment>
      <div className="container">
        {matches && matches.length > 0 && (
          <div className="box box--with-border">
            <h1>Komende wedstrijd</h1>

            <article className="match">
              <section>
                {matches.length === 0 ? (
                  <h2>Geen wedstrijden gepland</h2>
                ) : (
                  <Fragment>
                    <div className={`match__logos match__logos--${matches[0].home ? 'home' : 'away'}`}>
                      <img className="match__logos__svbc" alt="SVBC" src={process.env.PUBLIC_URL + "/logo.svg"} />
                      <img className="match__logos__opponent" alt={matches[0].title} src={matches[0].opponentLogo} />
                    </div>
                    <h1>{matches[0].result}</h1>
                    <h2>
                      {matches[0].title}
                    </h2>
                    <p>
                      {matches[0].date}
                    </p>
                  </Fragment>
                )}
              </section>
            </article>
          </div>
        )}

        {knvbMatches && (
          <Fragment>
            <div className="box box--with-border">
              <h1>Programma knvb</h1>
              <div className="toggles">
                {knvbMatches.home.length > 0 && (
                  <button
                    onClick={() => setKnvbMatchesActiveTab('home')}
                    className={`${knvbMatchesActiveTab !== 'home' ? 'inactive' : 'active'}`}
                  >
                    Thuis<span>wedstrijden</span><span className="tag">{knvbMatches.home.length}</span>
                  </button>
                )}
                {knvbMatches.home.length > 0 && (
                  <button
                    onClick={() => setKnvbMatchesActiveTab('away')}
                    className={`${knvbMatchesActiveTab !== 'away' ? 'inactive' : 'active'}`}
                  >
                    Uit<span>wedstrijden</span><span className="tag">{knvbMatches.away.length}</span>
                  </button>
                )}
              </div>
              <ul className="match-list">
                {knvbMatchesActiveTab === 'home' && (
                  <Fragment>
                    {knvbMatches.home.map((line) => (
                      <li key={line.match}>
                        <div className="match-list__item">
                          <p>{line.date} : {line.time}</p>
                          <h3>{line.match}</h3>
                        </div>
                      </li>
                    ))}
                    {knvbMatches.home.length === 0 && <li><p>Geen wedstrijden</p></li>}
                  </Fragment>
                )}
                {knvbMatchesActiveTab === 'away' && (
                  <Fragment>
                    {knvbMatches.away.map((line) => (
                      <li key={line.match}>
                        <div className="match-list__item">
                          <p>{line.date} : {line.time}</p>
                          <h3>{line.match}</h3>
                        </div>
                      </li>
                    ))}
                    {knvbMatches.away.length === 0 && <li><p>Geen wedstrijden</p></li>}
                  </Fragment>
                )}
              </ul>
            </div>
            <div className="box box--with-border">
              <h1>Uitslagen knvb</h1>
              <ul className="match-list">
                {knvbMatches.results && knvbMatches.results.slice(0,5).map((line) => (
                  <li key={line.match}>
                    <div className="match-list__item">
                      <p>{line.date} : {line.time}</p>
                      <h3>{line.match}</h3>
                      <p>{line.result}</p>
                    </div>
                  </li>
                ))}
                {(!knvbMatches.results || knvbMatches.results.length === 0) && <li><div className="match-list__item"><p>Geen resultaten</p></div></li>}
              </ul>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}
