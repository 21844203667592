import React, { useEffect, useState } from 'react';
// import { request } from 'graphql-request';
import {
  useParams
} from "react-router-dom";
import Loading from '../components/loading';
import HeaderBack from '../components/headerBack';
import { request } from '../context/data';

export default function NewsItem () {
  const [newsItem, setNewsItem] = useState(null);
  const [loading, setLoading] = useState(true);
  let { slug } = useParams();

  useEffect(() => {
    const fetchNewsItem = async () => {
      const data = await request(
        process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/graphql` : '/graphql',
        `query {
          post(id: "${slug}", idType:SLUG) {
            title
            content
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
        }`
        ,
        {}
      );
      setNewsItem(data.post || null);
      setLoading(false);
    }
    fetchNewsItem();
  }, [slug]);

  return (
    <div className="container">
      {loading && <Loading />}
      {newsItem && (
        <article className="box box--with-border">
          <HeaderBack><h1>{newsItem.title}</h1></HeaderBack>
          {newsItem.featuredImage && (
            <figure className="news__image">
              <img alt={newsItem.title} src={newsItem.featuredImage?.node?.mediaItemUrl ? newsItem.featuredImage.node.mediaItemUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNksPf9DwACYAGNJEiR2AAAAABJRU5ErkJggg=="} />
            </figure>
          )}
          <div dangerouslySetInnerHTML={{__html: newsItem.content}} />
        </article>
      )}
    </div>
  )
}
